import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Background from "../images/Bg6.png"
import GrowthArrow from "../images/pyxel-home-sec-5.png"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const IndexPage = (props) => {
    const data = useStaticQuery(graphql`
        query{
            homeLarge: file(relativePath: { eq: "design-devices-lg.png" }) {
                childImageSharp {
                        gatsbyImageData(
                        placeholder: NONE)
                }
            }
            homeSmall: file(relativePath: { eq: "design-devices-sm.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE)
                }
            }
        }
    `)

    let title = "Sacramento Web Design & SEO | Sacramento Web Development"
    let keywords = ["Sacramento Web Design", "Sacramento Web Development", "Sacramento Web Maintenance", "Sacramento SEO", "Sacramento Digital Marketing", "Sacramento Ecommerce"]
    let location = props.location.pathname
    let description = "Sacramento website agency specializing in web design, web development, web maintenance, SEO, Ecommerce, and Digital Marketing all while providing professional services at an affordable rate."
    return (
        <div>
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} />
                <div className="bg-no-repeat bg-cover bg-left"
                    style={{
                        backgroundImage: `url(${Background})`,
                        width: '100%',
                        height: '100%',
                        backgroundAttachment: 'absolute',
                    }}>
                    <div className="container grid gap-x-5 lg:grid-cols-2 text-white font-bold mx-auto">
                        <div className="flex justify-center p-10 pt-36 xl:py-52">
                            <div className="md:px-14 lg:px-0">
                                <p className="text-5xl md:text-6xl xl:text-7xl font-pixeboy">
                                    PROFESSIONAL
                                </p>
                                <h1 className="text-xl md:text-2xl uppercase">Web Design & Digital Marketing Solutions</h1>
                                <div className="max-w-lg md:max-w-xl font-normal text-sm md:text-lg">
                                    <p className="mt-7 md:mt-12">
                                        Our main priority is to provide quality website services to ensure the growth of your Online presence.<br /><br />
                                        We offer the highest standard of website development solutions including Website Design, Website Maintenance, Digital Marketing,
                                        SEO, and much more to help you leave your competition in the dust.
                                    </p>
                                    <div className="hidden lg:block mt-10 lg:mt-12 xl:mt-28">
                                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                            <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                                <span>Request Quote</span>
                                            </button>
                                        </Link>
                                        <div className="hover:text-gray-600 mt-4">
                                            <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                                Call Us: (916) 821-9044
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:pt-28 mx-auto">
                            <div className="lg:hidden">
                                <GatsbyImage image={data.homeSmall.childImageSharp.gatsbyImageData} className="max-w-sm mx-auto" placeholder="none" title="Sacramento Web Design" alt="Sacramento Web Design" />
                            </div>
                            <div className="hidden lg:block">
                                <GatsbyImage image={data.homeLarge.childImageSharp.gatsbyImageData} placeholder="none" title="Sacramento Web Design" alt="Sacramento Web Design" />
                            </div>
                        </div>
                        <div className="lg:hidden mx-auto mt-10 md:mt-12 xl:mt-28 pb-10">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                    <span>Request Quote</span>
                                </button>
                            </Link>
                            <div className="hover:text-gray-600 mt-2">
                                <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                    Call Us: (916) 821-9044
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="py-10 md:py-24 px-5">
                        <div className="container mx-auto grid gap-10 md:grid-cols-2">
                            <div className="mx-auto order-2 md:justify-end md:order-1 md:mt-24 lg:mt-0">
                                <StaticImage src="../images/customer-priority-2.png" placeholder="none" className="mx-auto w-56 md:w-max md:max-w-xs" title="Sacramento website services" alt="Sacramento website services" />
                            </div>
                            <div className="flex justify-center text-center order-1 md:justify-start md:text-left">
                                <div className="px-2">
                                    <h2 className="text-3xl font-bold md:text-4xl">
                                        OUR PRIORITY IS YOU!
                                    </h2>
                                    <hr className="mx-auto w-44 border-black border-2 mt-4 md:mx-0" />
                                    <p className="max-w-xl md:col-end-1">
                                        <br />Here at Pyxel Development, we strive to provide you with the best services around.
                                        No matter the situation, the customer is always our top priority and we are not satisfied until you are.<br /><br />
                                        We work hard to provide you with the best Digital Marketing, SEO, Custom Website Designs, and any other Website Services
                                        that will help you showcase your amazing business to the rest of the world, helping you generate more traffic,increase your profits, and spread your message.<br /><br />
                                        Whether you have a vision that you need help bringing to life, or need a professional custom website design that will suit your needs, we have you covered.
                                        We will work directly with you to ensure that your needs are met with our Website Services!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner">
                    <div className="xl:container mx-auto text-white text-center p-10 md:py-20 flex items-center">
                        <div className="mx-auto">
                            <h3 className="text-2xl font-bold md:text-4xl">
                                WHO WE ARE
                            </h3>
                            <p className="text-xs lg:text-sm max-w-2xl">
                                <br />Based out of Sacramento, California, Pyxel Development is a Software Development company specializing in, but not limited to, Website Development, Website Design, Digital Marketing, and SEO.
                                We are dedicated to providing quality services to every type of business and building strong relationships that will last. Every service that we provide is guaranteed to be unique to
                                each customer, so you can be sure that your website is 1-of-a-kind!
                            </p>
                            <Link to="/about" title="About Pyxel Development" alt="About Pyxel Development">
                                <button className="m-5 relative h-10 px-6 text-xs text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-gray-500 md:mt-20">
                                    <span>Learn More</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="bg-offblue">
                    <div className="py-10 md:py-24 px-5">
                        <div className="grid md:grid-cols-2 gap-2 max-w-max mx-auto">
                            <div className="mx-auto md:order-2 flex items-center md:items-start">
                                <div className="px-5 max-w-lg lg:py-24 lg:max-w-2xl">
                                    <span className="text-white"> WHAT WE DO </span>
                                    <h2 className="font-bold text-3xl md:text-4xl lg:text-5xl">WE HELP YOU GROW</h2>
                                    <p className="text-sm max-w-3xl lg:text-base">
                                        <br />With the help of our top-tier solutions, we assist businesses in reaching their goals and expanding their online presence
                                        by implementing our website design, website development, digital marketing, and SEO services.
                                    </p>
                                </div>
                            </div>
                            <div className="pt-5 md:pt-0">
                                <div className="grid grid-cols-3 text-center text-white text-sm text-bold  ">
                                    <div className="p-2 max-w-xs border-black border-r-2 border-b-2 hover:text-gray-700">
                                        <Link to="/sacramento-web-design" title="Sacramento Web Design" alt="Sacramento Web Design">
                                            <h3 className="font-bold  text-xs lg:text-lg">WEBSITE<br />DESIGN</h3>
                                            <p className="text-xs hidden lg:flex text-black py-4">
                                                Responsive, engaging, and unique designs to fit your specific needs
                                            </p>
                                            <div className="py-4 flex justify-center ">
                                                <WebDesignIcon />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2 max-w-xs border-black border-b-2 border-r-2 hover:text-gray-700">
                                        <Link to="/sacramento-web-development" title="Sacramento Web Development" alt="Sacramento Web Development">
                                            <h3 className="font-bold text-xs lg:text-lg">WEBSITE<br />DEVELOPMENT</h3>
                                            <p className="text-xs hidden lg:flex text-black py-4">
                                                Custom solutions that are optimized  for search engines and load speeds
                                            </p>
                                            <div className="py-4 flex justify-center ">
                                                <WebDevIcon />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2 max-w-xs border-black border-b-2 hover:text-gray-700">
                                        <Link to="/sacramento-ecommerce" title="Sacramento Web Development" alt="Sacramento Web Development">
                                            <h3 className="font-bold text-xs lg:text-lg uppercase">Ecommerce<br />Website</h3>
                                            <p className="text-xs hidden lg:flex text-black py-4">
                                                Custom blazing fast Ecommerce website connected to the API of your choice
                                            </p>
                                            <div className="py-4 flex justify-center ">
                                                <EcommerceIcon />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2 max-w-xs border-black border-r-2 hover:text-gray-700">
                                        <Link to="/sacramento-web-maintenance" title="Sacramento Web Maintenance" alt="Sacramento Web Maintenance">
                                            <h3 className="font-bold text-xs lg:text-lg">WEBSITE<br />MAINTENANCE</h3>
                                            <p className="text-xs hidden lg:flex text-black py-4">
                                                Keep your website running in optimal conditions and up to date
                                            </p>
                                            <div className="py-4 flex justify-center ">
                                                <WebMaintenanceIcon />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2 max-w-xs border-black border-r-2 hover:text-gray-700">
                                        <Link to="/sacramento-digital-marketing" title="Sacramento Digital Marketing" alt="Sacramento Digital Marketing">
                                            <h3 className="font-bold text-xs lg:text-lg">DIGITAL<br />MARKETING</h3>
                                            <p className="text-xs hidden lg:flex text-black py-4">
                                                Proven marketing strategies to improve online presence, credibility, and revenue
                                            </p>
                                            <div className="py-4 flex justify-center">
                                                <DigitalMarketingIcon />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2 max-w-xs hover:text-gray-700">
                                        <Link to="/sacramento-seo" title="Sacramento SEO" alt="Sacramento SEO">
                                            <h3 className="font-bold text-xs lg:text-lg">SEARCH ENGINE<br />OPTIMIZATION</h3>
                                            <p className="text-xs hidden lg:flex text-black py-4">
                                                Search Engine Optimization (SEO) service using strategic placement of keywords
                                            </p>
                                            <div className="py-4 flex justify-center ">
                                                <SEOIcon />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex justify-center pt-5">
                                    <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                        <button className="m-5 relative h-10 px-6 text-xs transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-white">
                                            <span>Request Quote</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner overflow-hidden">
                    <div className="container mx-auto">
                        <div className="py-20 md:py-36">
                            <div className="relative text-left text-white pl-5 md:text-center">
                                <div className="mx-auto max-w-lg lg:max-w-xl xl:max-w-3xl">
                                    <p className="mx-auto text-2xl font-bold md:text-4xl max-w-lg">
                                        DESIGN, GROW, PROFIT
                                    </p>
                                    <p className="text-xs max-w-xs pr-16 md:pr-0 md:mx-auto md:max-w-lg lg:text-sm lg:max-w-xl xl:max-w-3xl 2xl:text-base">
                                        <br />Pyxel Development is determined to help take your business to the next level. Whether your are looking to create your first website,
                                        redesign your current website, promote your business with digital marketing and/or SEO, we are here to help you succeed!
                                    </p>
                                </div>
                                <div>
                                    <img src={GrowthArrow} title="Business Growth" alt="Business Growth" className="absolute w-64 md:w-80 lg:w-96 arrow-pos" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-offblue">
                    <div className="px-8 py-10 order-last lg:px-20 lg:py-24">
                        <form name="Home Page Contact" action="/success" method="POST" data-netlify="true" className="w-full max-w-3xl px-14 py-5 lg:px-6 xl:px-14 bg-white mb-14 mt-12 rounded-2xl mx-auto">
                            <input type="hidden" name="form-name" value="Home Page Contact" />
                            <p className="text-center text-3xl font-bold mt-2">CONTACT US!</p>
                            <hr className="mb-6 border border-black " />
                            <div className="flex flex-wrap -mx-2 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">First Name:
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" required name="firstname" placeholder="Ex: Peter" />
                                    </label>
                                    <p className="text-xs italic">*Required</p>
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">Last Name:
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" name="lastname" placeholder="Ex: Ross" />
                                    </label>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-2 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="company-name">Company Name:
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="company-name" type="text" name="company" placeholder="Ex: Pyxel Development" />
                                    </label>
                                    <p className="text-xs italic">*Optional</p>
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Phone:
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" type="tel" name="phone" placeholder="Ex: 123-456-7890" />
                                    </label>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" required htmlFor="email">E-mail:
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" name="email" required placeholder="Ex: PeterRoss@mail.com" />
                                    </label>
                                    <p className="text-xs italic">*Required</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">Question / Message:
                                        <textarea className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" id="message" required name="message" placeholder="i.e. Questions, Comments, Concerns, etc..."></textarea>
                                    </label>
                                    <p className="text-xs italic">*Required</p>
                                </div>
                            </div>
                            <div className="flex justify-center -mt-2 mb-4 mx-auto">
                                <button type="submit" title="Send Pyxel Development a Message" alt="Send Pyxel Development a Message" className="shadow bg-black border hover:bg-white hover:text-black hover:border-black focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-xl w-48" >Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
export default IndexPage

function WebDesignIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="black">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
        </svg>
    )
}

function WebDevIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
    )
}

function WebMaintenanceIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
    )
}

function DigitalMarketingIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    )
}

function SEOIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
        </svg>
    )
}

function EcommerceIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
    )
}